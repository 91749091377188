
.top-bar {
  background: white;
  margin-left: 220px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 1rem;
  padding: 0.5rem;
  box-shadow: 0 0 4px 2px #ddd;
  text-align: right;
}

.sign-out-button {
  background: #fff;
  outline: none;
  border: 0;
  border-radius: 0.3rem;
  padding: 0.3rem 1rem;
  cursor: pointer;
  transition: ease 0.3s all;
  color: #c54040;
}

.sign-out-button:hover {
  background: #eee;
}

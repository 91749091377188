body {
  background: #eee;
}

.main {
  margin-left: 240px;
  margin-right: 20px;
}

.ReactTable {
  border: 1px solid rgba(0,0,0,0.1);
  background: #fff;
  padding: 1rem;
  border-radius: 0.4rem;
  margin-top: 1em;
}

.first-payment {
  display: flex;
}

.first-payment label {
  margin-left: 10px;
  margin-right: 10px;
}
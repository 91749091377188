.reset-password-page{
  height: 100vh;
	display: flex;
	background-image: url(images/alternative-energy-building-clouds-energy-356036.png);
	/* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.reset-password-logo img {
	margin-bottom: 15px;
	color: #ffffff;
	position: fixed;
  top: 20%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.reset-password-form {
	font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
	Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
	sans-serif;
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 420px;
  margin: auto;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.reset-password-form input {
	font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
	Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
	sans-serif;
	outline: 0;
	background: #F6F9FF;
	border: 1.4px solid grey !important;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
	font-size: 14px;
}

.reset-password-form input[type="text"]:focus {
	border: 2px solid #007aff !important;
}
.reset-password-form input[type="password"]:focus {
	border: 2px solid #007aff !important;
}

.reset-password-form button {
	font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
	Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
	sans-serif;  outline: 0;
  background: #aeaeb7;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFF;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reset-password-form p {
  text-align: justify;
  margin: 15px 0px;
	color: #838388;
}

.reset-password-form h3 {
	color: #838388;
}

.reset-password-form button:hover,.form button:active,.form button:focus {
  background: #2479ff;
}

.reset-password-form a:link{
	font-family: "Poppins",-apple-system,BlinkMacSystemFont,"Segoe UI", Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue", sans-serif;
	color: #007aff;
}

.reset-email-sent-message {
  text-align: justify;
  margin-bottom: 15px;
}

.sidebar {
  transition: all 0.3s;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  width: 220px;
  background-color: #1e1f26;
  color: #fff;
}

.app-name {
  padding: 1rem;
  border-bottom: 2px solid #333;
  text-transform: uppercase;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  border-left:3px solid transparent;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #333;
  text-decoration: none;
  display: block;
  font-family: sans-serif;
  color: #fff;
}

.sidebar .nav-link span {
  margin-right: 0.5rem;
}

.sidebar .nav-link:hover {
  padding-left: 2rem;
  transition: 0.4s all ease;
  background: #fff;
  color: #333;
}

.sidebar .nav-link.active {
  border-left-color: #009688;
  color: #009688;
}
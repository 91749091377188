.loading{
	display: flex;
	height: 100vh;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.loader-text{
	margin-top: 1rem;
	font-family: sans-serif;
	font-size: 1.4rem;
}

.loader {
  width: 60px;
  height: 60px;
  border: 3px solid lightgrey;
  border-left-color: black;
  border-radius: 50%;
  -webkit-animation: rotation 0.5s linear infinite;
          animation: rotation 0.5s linear infinite;
}

.mini-loading{
	display: flex;
	align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.mini-loader {
	width: 20px;
  height: 20px;
  border: 2px solid lightgrey;
  border-left-color: black;
  border-radius: 50%;
  -webkit-animation: rotation 0.5s linear infinite;
          animation: rotation 0.5s linear infinite;
}

.mini .loader-text{
	font-family: 1rem;
}

@-webkit-keyframes rotation {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotation {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
